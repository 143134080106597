<template>
  <div class="addenterprise">
    <div class="essential_information">
      <div class="store_table">
        <div class="store_table_item active">
          <span>企业资料</span>
        </div>
      </div>
      <div class="form_essential">
        <el-form ref="form" :model="form" :rules="rules" label-width="90px">
          <div>
            <el-form-item label="企业头像:" prop="companyLogo">
              <uploadLogo
                :imageShow="form.companyLogo"
                @uploadOneImgShow="uploadOneImgShow"
              ></uploadLogo>
            </el-form-item>
            <!-- <el-form-item label="企业名称:" prop="companyFullName">
              <div class="companyName">
                <el-input v-model="form.companyFullName" placeholder="请输入企业名称" />
              </div>
            </el-form-item> -->
            <el-form-item label="企业全称:" prop="companyFullName">
              <el-input v-model="form.companyFullName" placeholder="请输入企业全称" />
            </el-form-item>
            <el-form-item label="所在地区:" prop="district">
              <div class="select_inner">
                <el-select v-model="form.province" placeholder="请选择省份" @change="citychange">
                  <el-option
                    v-for="item in operatingList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="form.city" placeholder="请选择市" @change="districtchange">
                  <el-option
                    v-for="item in operatingLists"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="form.district" placeholder="请选择区">
                  <el-option
                    v-for="item in districtList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item label="企业规模:" prop="enterpriseScale">
              <el-radio-group v-model="form.enterpriseScale">
                <!-- <el-radio :label="'1'">特大型</el-radio> -->
                <el-radio :label="'大型'">大型</el-radio>
                <el-radio :label="'中型'">中型</el-radio>
                <el-radio :label="'小型'">小型</el-radio>
                <el-radio :label="'微型'">微型</el-radio>
                <!-- <el-radio :label="'6'">其他</el-radio> -->
              </el-radio-group>
            </el-form-item>
            <el-form-item label="企业简介:" prop="enterpriseIntro">
              <el-input
                v-model="form.enterpriseIntro"
                type="textarea"
                placeholder="请输入企业简介"
              />
            </el-form-item>
          </div>
          <div class="store_table">
            <div class="store_table_item active">
              <span>联络人信息</span>
            </div>
            <div
              class="input_flex"
              style="display: flex"
              v-for="(item, index) in form.liaisonsCompanyResultList"
              :key="index"
            >
            <div>
              <el-form-item label="联络人:" prop="liaisonsName">
                <el-input v-model="item.liaisonsName" placeholder="请输入联络人" />
              </el-form-item>
              <el-form-item label="联系方式:" prop="liaisonsContactWay">
                <el-input v-model="item.liaisonsContactWay" placeholder="请输入联系方式" />
              </el-form-item>
              <el-form-item label="联络人职位:" prop="liaisonsPosition">
               
             <div class="radio_flex">
              <el-radio-group v-model="item.liaisonsPosition">
                <el-radio v-for="(el,indexs) in liaisonsList" :key="indexs" :label="el.name">{{ el.name }}</el-radio>
              
                <el-radio :label="'其他'">其他</el-radio>        
              </el-radio-group>
        
              
              <div class="radio_input" style="margin-left: 5px;" v-if="item.liaisonsPosition=='其他'">
                <el-input v-model="item.other" placeholder="请输入联系方式" />
              </div>
             </div>
            </el-form-item>
            </div>
              <div style="display: flex; font-size: 24px; margin-left: 10px">
                  <span v-if="index == 0" style="color: #4e93fb" @click="addenterprise"
                    ><i class="el-icon-circle-plus"></i
                  ></span>
                  <span v-if="index > 0" style="color: #ff7575" @click="delenterprise(index)"
                    ><i class="el-icon-delete"></i
                  ></span>
                </div>
            </div>
          </div>
        </el-form>
      </div>

      <div style="margin-top: 30px" class="footer_bottom">
        <el-button v-if="!id" type="primary" @click="submitForm">保存</el-button>
        <el-button @click="clcans">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import uploadLogo from '../components/uploadLogo.vue'
import { getCompanyDetail, addcompany, editcompany, getcompany } from '@/api/datement'
import { getCityAll } from '@/api/demand'

export default {
  name: 'addenterprise',
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  components: { uploadLogo },
  data() {
    return {
      form: {
        userSchema:'',
        city: '', //城市
        companyFullName: '', //企业全称 
        companyLogo: '', //企业logo
        district: '', //区/县
        enterpriseIntro: '', //企业简介
        enterpriseScale: '', //企业规模
        id:'',
        liaisonsCompanyResultList: [
          {
            clientCompanyId: '', //客户企业编号
            liaisonsCompanyId: '', //企业客户联络人主键
            liaisonsContactWay: '', //联络方式
            liaisonsName: '', //联络人姓名
            other:'', 
            liaisonsPosition: '' //联络人职位
          }
        ], //客户企业联络人信息
      
        province: '' //省份
      }, //表单收集
      parteninfo:JSON.parse(sessionStorage.getItem('typeMode')),
     liaisonsList:[
      {
        id:1,
        name:'CEO'
      },
      {
        id:2,
        name:'CIO'
      },
      {
        id:3,
        name:'IT负责人'
      },
      {
        id:4,
        name:'项目负责人'
      }
     ],
     
      operatingLists: [],
      operatingList: [],
      districtList: [],
      rules: {
        companyFullName: [{ required: true, message: '请输入企业全称', trigger: 'blur' }],
        // officialWebsite: [{ required: true, message: '请输入企业官网', trigger: 'blur' }],
        // commodityName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        // enterpriseScale: [{ required: true, message: '请输入企业规模', trigger: 'blur' }],
        // enterpriseIntro: [{ required: true, message: '请输入企业简介', trigger: 'blur' }],
        // companyLogo: [{ required: true, message: '请上传logo', trigger: 'change' }]
      }
    }
  },
  created() {
    if (this.id) {
      this.getcompany()
    }else{
      this.incity()
    }
   
  },
  methods: {
    clcikChecked(val) {
      console.log(val)

      this.form.foshanMember = val
    },
    // 图片上传路径长地址  logo
    uploadOneImgShow(value) {
      this.form.companyLogo = value
    },
    //查询企业信息回显
    async getcompany() {
      const query = { id: this.id }
      const res = await getCompanyDetail(query)
      if (res.data.resultCode == 200) {
        this.form = res.data.data
        if (this.form.province && this.form.city) {
          this.incity(this.form.province, this.form.city)
        }
        if(!this.form.liaisonsCompanyResultList.length){
          this.form.liaisonsCompanyResultList=[
          {
            clientCompanyId: '', //客户企业编号
            liaisonsCompanyId: '', //企业客户联络人主键
            liaisonsContactWay: '', //联络方式
            liaisonsName: '', //联络人姓名
            other:'', 
            liaisonsPosition: '' //联络人职位
          }
          ]
        }else{
          console.log(321);
          this.form.liaisonsCompanyResultList.forEach((i,index)=>{
            let findVal =  this.liaisonsList.find(item => item.name === i.liaisonsPosition)
            if(!findVal){          
              this.$set(i, 'other', i.liaisonsPosition)
              i.liaisonsPosition='其他'
            }
          })
        }

      }
    },

    //城市
    async incity(province, city) {
      const res = await getCityAll()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
        if(province){
          this.citychange(province)
        }
       
        if (city) {
          this.operatingLists.forEach((o) => {
            if (this.form.city == o.name) {
              this.form.district = ''
              this.districtList = this.operatingLists.find((el) => el.name == city).cityResults
            }
          })
        }
      }
    },
    //选择城市
    citychange(item) {
     
      this.operatingList.forEach((o) => {
        if (this.form.province == o.name) {
          let isnum = item.indexOf('市')
          if (isnum == -1) {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          } else {
            this.form.city = item
            this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            this.operatingLists = []
            this.operatingLists.push(o)
          }
        }
      })
    },
    //选择区
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.form.city == o.name) {
          this.form.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
    },

  //新增联络人
  addenterprise(){
    this.form.liaisonsCompanyResultList.push(
      {
            clientCompanyId: '', //客户企业编号
            liaisonsCompanyId: '', //企业客户联络人主键
            liaisonsContactWay: '', //联络方式
            liaisonsName: '', //联络人姓名
            other:'',   
            liaisonsPosition: '' //联络人职位
          }
    )
  },
  //删除新增联络人
  delenterprise(index){
    this.form.liaisonsCompanyResultList.splice(index,1)
  },
    //获取长地址图片
    uploadOneImgShowDian(val) {
      this.form.companyLogo = val
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.form.companyLogo = ''
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.companyLogo = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.companyLogo = ''
    },

    /** 提交按钮 */
    submitForm() {
      if(this.form.liaisonsCompanyResultList[0].liaisonsName){
        this.form.liaisonsCompanyResultList.forEach(el=>{
          if(el.liaisonsPosition=='其他'){
            el.liaisonsPosition=el.other
          }
        })
      }
      this.form.userSchema=this.parteninfo.id
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.id) {
            addcompany(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          } else {
            editcompany(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          }
        }
      })
    },
    clcans() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>



::v-deep .essential_information .companyName .el-input {
  width: 596px;
}
::v-deep .essential_information .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 596px;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .essential_information .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .select_inner .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 192px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 590px;
}
::v-deep .date_picker .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 30px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .radio_input .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 192px;
}
.addenterprise {
  background: #ffffff;
  padding: 10px 35px;
  
  .store_table {
    box-sizing: border-box;
    margin: 0 72px 20px 0;
    display: flex;
    flex-direction: column;
    .input_flex{
      margin-top: 20px;
      .radio_flex{
        display: flex;
        align-items: center;
        height: 40px;
      }
    }
    .store_table_item {
      color: #999999;
      margin-right: 50px;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 20px;
    }

    .active {
      color: #4e93fb;
      font-size: 20px;
      border-radius: 1px;
    }
  }

  .img_tips {
    position: relative;
    top: -10px;
    color: #6ba1ff;
  }

  .essential_information {
    h4 {
      color: #146aff;
    }
  }

  .label_management {
    padding: 30px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: right;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
  .footer_bottom {
    border-top: 1px solid #f5f5f5;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
